import { RestClient } from "lib/api/RestClient";

export class FATableClient {
  private static _client: RestClient;

  static fetchFareRevenueClassMix(params) {
    return FATableClient.getClient().getCall(
      `/api/msdv2/temp?${new URLSearchParams(params)}`
    );
  }

  static getClient() {
    if (FATableClient._client == null) {
      FATableClient._client = new RestClient();
    }
    return FATableClient._client;
  }
}
