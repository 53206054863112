import FAREANALYZER from "modules/lfa/fareAnalyzer";
import React from "react";

const FA = () => {
  return (
    <div>
      <FAREANALYZER />
    </div>
  );
};

export default FA;
