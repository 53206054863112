import { IEAFilterContext } from "types/types";
import React, { ReactNode, useState } from "react";

type Props = {
  children: ReactNode;
};

interface ContextState {
  eaFilterList: IEAFilterContext;
  setEaFilterList: React.Dispatch<React.SetStateAction<IEAFilterContext>>;
}

const contextDefaultValues: ContextState = {
  eaFilterList: {
    origin_city: [],
    destination: [],
    event_ids: [],
    group_ids: [],
    updated: false,
  },
  setEaFilterList: () => {},
};
const EAFilterContext = React.createContext<ContextState>(contextDefaultValues);
const EAFilterConsumer = EAFilterContext.Consumer;

const EAFilterContextApp = ({ children }: Props) => {
  const [eaFilterList, setEaFilterList] = useState<IEAFilterContext>({
    origin_city: [],
    destination: [],
    event_ids: [],
    group_ids: [],
    updated: false,
  });

  return (
    <EAFilterContext.Provider value={{ eaFilterList, setEaFilterList }}>
      {children}
    </EAFilterContext.Provider>
  );
};

export { EAFilterContext, EAFilterConsumer, EAFilterContextApp };
