import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import styles from "./styles.module.css";

type Anchor = "top" | "left" | "bottom" | "right";
interface ISideDrawer {
  children: React.ReactNode;
  MenuButton: JSX.Element;
  direction?: Anchor;
  width?: string | number;
  shutdownTrigger?: () => void;
}

export default function SideDrawer({
  children,
  MenuButton,
  direction = "left",
  width = 280,
  shutdownTrigger,
}: ISideDrawer) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
      if (shutdownTrigger && open === false) {
        shutdownTrigger();
      }
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : width,
        color: "inherit",
      }}
      role="presentation"
      className={styles.container}
      //   onClick={toggleDrawer(anchor, false)}
      //   onKeyDown={toggleDrawer(anchor, false)}
    >
      {children}
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Button
          onClick={toggleDrawer(direction, true)}
          style={{ color: "#fff", padding: 0 }}
        >
          {MenuButton}
        </Button>
        <SwipeableDrawer
          anchor={direction}
          open={state[direction]}
          onClose={toggleDrawer(direction, false)}
          onOpen={toggleDrawer(direction, true)}
          sx={{
            ".MuiDrawer-paper": {
              bgcolor: "transparent",
            },
          }}
        >
          {list(direction)}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
