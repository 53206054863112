import { useContext, useState } from "react";
import PriceEvolution from "components/lfa/PriceEvolution/PriceEvolution";
import AvaibilityTrendsFilters from "components/lfa/AvaibilityTrendsFilters/AvaibilityTrendsFilters";
import Layout from "components/common/Layout/Layout";
import { FlightTypeSwitchContext } from "context-api/OwRtContext";
import { AvaibilityTrendsFilterContext } from "context-api/AvaibilityTrendsFilterContext";
import { FilterContext } from "context-api/FilterContext";
import { ThemeContext } from "context-api/ThemeContext";
import axios from "axios";
import FareTrendsTable from "components/lfa/FareTrendsTable";
import moment from "moment";

export default function PRICEEVOLUTION() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);
  const [availabilityFilter, setAvailabilityFilter] = useState(false);
  const [data, setData] = useState<{ data: any[]; columns: any[] }>({
    data: [],
    columns: [],
  });
  const [activeStep, setActiveStep] = useState(169);
  const [selectedCarriers, setSelectedCarriers] = useState<string[]>([]);
  const [selectedFlightNumbers, setSelectedFlightNumbers] = useState<string[]>(
    []
  );

  const { owRtSwitch } = useContext(FlightTypeSwitchContext);
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState<{
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  }>({
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 1,
  });
  const { avaibilityTrendsFilterList } = useContext(
    AvaibilityTrendsFilterContext
  );
  const { filterList } = useContext(FilterContext);
  const { theme } = useContext(ThemeContext);

  const getAuthToken = () => {
    let token: any = null;
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].split("=");
      if (cookie[0].trim().toLocaleLowerCase() === "token") {
        token = cookie[1];
      }
    }
    return token;
  };

  async function handleExport() {
    if (owRtSwitch) {
      const params: any = {
        ...avaibilityTrendsFilterList,
        orig_city_airport: filterList.origCityAirport,
        dest_city_airport: filterList.destCityAirport,
        cabin: activeStep === 169 ? "ECO" : activeStep === 85 ? "BUS" : "FIRST",
        outbound_date: moment(
          avaibilityTrendsFilterList.date_range_start
        ).format("YYYY-MM-DD"),
        graph_carriers: selectedCarriers?.join(),
        flight: selectedFlightNumbers,
        type: owRtSwitch.toUpperCase(),
        dark_theme: theme === "dark",
        currency: filterList.currency,
        page: meta.currentPage,
      };
      const url = `/api/msdv2/fare-structure/price-evolution-report?${new URLSearchParams(
        params
      )}`;
      try {
        const response = await axios({
          url,
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
          method: "GET",
          responseType: "blob", // important
        });

        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "data.csv";
        link.click();
      } catch (error) {
        console.error(error);
      }
    }
  }

  return (
    <Layout
      filterIsOpen={filterIsOpen}
      setFilterIsOpen={setFilterIsOpen}
      title={"Price Evolution"}
    >
      <div style={{ marginTop: 24 }}>
        <AvaibilityTrendsFilters
          isOpen={availabilityFilter}
          setIsOpen={setAvailabilityFilter}
          isPriceEvo
          isSingleDate
        />
      </div>
      <div>
        <PriceEvolution
          filterIsOpen={filterIsOpen}
          isOpen={availabilityFilter}
          setData={setData}
          setLoading={setLoading}
          meta={meta}
          setMeta={setMeta}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          selectedCarriers={selectedCarriers}
          setSelectedCarriers={setSelectedCarriers}
          selectedFlightNumbers={selectedFlightNumbers}
          setSelectedFlightNumbers={setSelectedFlightNumbers}
        />
      </div>
      <div>
        <FareTrendsTable
          data={data}
          loading={loading}
          meta={meta}
          setMeta={setMeta}
          handleExport={handleExport}
        />
      </div>
    </Layout>
  );
}
