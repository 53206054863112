import Card from "components/common/Card/Card";
import CustomAtarevTable from "components/common/Table/CustomTable";
import styles from "./styles.module.css";
import images from "constans/images";
import { Tooltip } from "components/common/Tooltip";

const FareTrendsTable = ({ data, loading, meta, setMeta, handleExport }) => {
  return (
    <Card variant="secondary">
      <div className={styles.card_header}>
        <span className={styles.card_title}>Fare Trends Table</span>
        <Tooltip title={"Export Excel"}>
          <span className={styles.export_button} onClick={handleExport}>
            <img src={images.excel} alt="" />
          </span>
        </Tooltip>
      </div>
      <Card variant="primary">
        <CustomAtarevTable
          data={data.data}
          columns={data.columns}
          loader={loading}
          pagination
          meta={meta}
          setMeta={setMeta}
          defaultRowsPerPage={meta?.itemsPerPage ?? 20}
          height={600}
          isManageMenu={false}
        />
      </Card>
    </Card>
  );
};

export default FareTrendsTable;
