import { ReactNode, useContext } from "react";
import styles from "./styles.module.css";
import { ThemeContext } from "context-api/ThemeContext";
import { Tooltip } from "components/common/Tooltip";

type Props = {
  children: ReactNode;
  data: {
    max_weekday: string;
    max_date: string;
    max_time: string;
    max_lf?: string;
  };
  carrierColor?: string;
};


export default function StatisticTooltip({
  children,
  data,
  carrierColor = "#3f365a",
}: Props) {
  const { theme } = useContext(ThemeContext);
  return (

      <Tooltip title={htmlElement(data, carrierColor)}>
        <span className={styles.children} data-theme={theme}>
          {children}
        </span>
      </Tooltip>
  );
}
const htmlElement = (data, carrierColor) => {
  return (
    <div className={styles.container} style={{ backgroundColor: carrierColor }}>
      <span className={styles.wrapper}>
        <span className={styles.title}>Departure Date</span>:
        <span className={styles.description}>{data?.max_date ?? "-"}</span>
      </span>
      <span className={styles.wrapper}>
        <span className={styles.title}>Departure Time</span>:
        <span className={styles.description}>{data?.max_time ?? "-"}</span>
      </span>
      <span className={styles.wrapper}>
        <span className={styles.title}>Days of Week</span>:
        <span className={styles.description}>{data?.max_weekday ?? "-"}</span>
      </span>
      {data?.max_lf && (
        <span className={styles.wrapper}>
          <span className={styles.title}>LF</span>:
          <span className={styles.description}>{data?.max_lf ?? "-"}</span>
        </span>
      )}
    </div>
  );
};
