/* eslint-disable react-hooks/exhaustive-deps */
import Card from "components/common/Card/Card";
import CustomAtarevTable from "components/common/Table/CustomTable";
import { Tooltip } from "components/common/Tooltip";
import { FilterContext } from "context-api/FilterContext";
import { ThemeContext } from "context-api/ThemeContext";
import { FareStructureFareStructureTablesClient } from "lib/api/msd/fareStructureFareStructureTables/FareStructureFareStructureTables";
import { useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";

interface ILowComp {
    filterIsOpen: boolean;
}
export default function LowComp({ filterIsOpen }: ILowComp) {
    const [data, setData] = useState<any[]>([]);
    const { filterList } = useContext(FilterContext);
    const [activeStep, setActiveStep] = useState(0);
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        if (filterIsOpen === false) {
            FareStructureFareStructureTablesClient.fetchFareStructureFareStructureTables({
                filterList,
            })
                .then((response: any) => {
                    setData(response.comp_tables ?? []);
                })
                .catch(() => {
                    setData([]);
                });
        }
    }, [filterIsOpen]);

    const getColumns = labels => {
        const array: any = [];
        labels?.forEach(element => {
            for (const [field, name] of Object.entries(element)) {
                if (field !== "buyup_ratio" && field !== "pax_ratio") {
                    const obj = {
                        field,
                        name,
                        cell: row => (
                            <Tooltip title={row[field]}>
                                <span className={"rdb-custom-cell"}>{row[field]}</span>
                            </Tooltip>
                        ),
                    };
                    array.push(obj);
                } else if (field === "buyup_ratio") {
                    const obj = {
                        name,
                        field,
                        cell: row => (
                            <Tooltip title={row[field]?.text}>
                                <div className={styles.custom_table_row}>
                                    <span
                                        className={styles.row}
                                        style={{ width: row[field]?.ratio * 32 }}
                                        data-active={"blue"}
                                    />
                                </div>
                            </Tooltip>
                        ),
                        valueGetter: row => row[field]?.ratio,
                    };
                    array.push(obj);
                } else if (field === "pax_ratio") {
                    const obj = {
                        name,
                        field,
                        cell: row => (
                            <Tooltip title={row[field]?.text}>
                                <div className={styles.custom_table_row}>
                                    <span
                                        className={styles.row}
                                        style={{ width: row[field]?.ratio * 32 }}
                                    />
                                </div>
                            </Tooltip>
                        ),
                        valueGetter: row => row[field]?.ratio,
                    };
                    array.push(obj);
                }
            }
        });
        return array;
    };

    return (
        <Card variant="secondary" width={"50%"} height={648}>
            <div className={styles.card_title_wrapper}>
                <div className={styles.slider}>
                    <span
                        className={styles.prev_arrow}
                        onClick={() => {
                            if (activeStep > 0) {
                                setActiveStep(activeStep - 1);
                            } else {
                                setActiveStep(data.length - 1);
                            }
                        }}
                        data-theme={theme}
                    >
                        <span className={styles.arrow_left} data-theme={theme}></span>
                    </span>
                    <span className={styles.slider_title}>
                        {`${(Array.isArray(data) && data[activeStep]?.title) ?? ""}`}
                    </span>
                    <span
                        className={styles.next_arrow}
                        onClick={() => {
                            if (activeStep < data?.length - 1) {
                                setActiveStep(activeStep + 1);
                            } else {
                                setActiveStep(0);
                            }
                        }}
                        data-theme={theme}
                    >
                        <span className={styles.arrow_right} data-theme={theme}></span>
                    </span>
                </div>
                <div className={styles.active_wrapper}>
                    {data?.map((_item, index) => (
                        <span
                            className={styles.active_slide}
                            data-active={activeStep === index}
                            key={index}
                        />
                    ))}
                </div>
            </div>
            <CustomAtarevTable
                data={Array.isArray(data) ? data[activeStep]?.data ?? [] : []}
                columns={Array.isArray(data) ? getColumns(data[activeStep]?.labels) : []}
                pagination
                isShowing={false}
                height={504}
            />
        </Card>
    );
}
