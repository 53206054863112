/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./styles.module.css";
import { useContext, useEffect, useState } from "react";
import { HolidaysFilterClient } from "lib/api/holidays/GetFilterOptions";
import { CiStar } from "react-icons/ci";
import SideDrawer from "components/common/SideDrawer";
import MenuItem from "./FilterMenu";
import { EAFilterContext } from "context-api/FilterContextEA";
// import CompareFilterObjects from "utils/CompareFilterObjects";
import { UserProfileContext } from "context-api/UserProfile";
import DropdownCityAirport from "components/common/Dropdown/DropdownCountryCity";
import { HolidaysFilterSelectedSaveClient } from "lib/api/holidays/PostFilterOptions";

interface ICategories {
  label: string;
  value: string;
  sub_categories: {
    label: string;
    value: string;
    events: {
      event_name: string;
      event_id: string;
      selected: boolean;
      group_id: null | number;
    }[];
  }[];
}

const HolidaysFilter = () => {
  const [origin, setOrigin] = useState<any[]>([]);
  const [destination, setDestination] = useState<any[]>([]);
  const [categories, setCategories] = useState<ICategories[]>([]);
  const [categoriesInitial, setCategoriesInitial] = useState<ICategories[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCountryCity, setSearchCountryCity] = useState("");

  const { user } = useContext(UserProfileContext);
  const { eaFilterList, setEaFilterList } = useContext(EAFilterContext);

  const [expandSubCategory, setExpandSubCategory] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    if (categoriesInitial.length < 1) {
      const initialExpandSubCategoryState: { [key: string]: boolean } = {};
      categories.forEach((category) => {
        category.sub_categories.forEach((subCategory) => {
          initialExpandSubCategoryState[subCategory.value] = false;
        });
      });
      setExpandSubCategory(initialExpandSubCategoryState);
    }
  }, [categoriesInitial]);

  useEffect(() => {
    if (user) {
      setEaFilterList((prevState) => ({
        ...prevState,
        origin_city: user.defaultFilterSelection.ea.origCityAirport,
        destination: user.defaultFilterSelection.ea.destCityAirport,
      }));
    }
  }, [user?.defaultFilterSelection]);

  const getHolidaysOptions = () => {
    const params = {
      target: "ea",
      origin_city: eaFilterList.origin_city,
      destination: eaFilterList.destination,
      lookup: searchTerm,
      country_city_lookup: searchCountryCity,
    };
    HolidaysFilterClient.fetchFilterOptions(params)
      .then((res: any) => {
        const origins = [
          ...res?.origins?.map((el) => ({
            code: el?.country_code,
            name: el?.country_name,
            airports: [
              ...el?.cities.map((city) => ({
                airport_name: city?.city_name,
                airport_code: city?.city_code,
                city_code: el?.country_code,
              })),
            ],
          })),
        ];
        const destinations = [
          ...res?.destinations?.map((el) => ({
            code: el?.country_code,
            name: el?.country_name,
            airports: [
              ...el?.cities.map((city) => ({
                airport_name: city?.city_name,
                airport_code: city?.city_code,
                city_code: el?.country_code,
              })),
            ],
          })),
        ];
        const responseCategories: ICategories[] = res.categories ?? [];
        let selecteds: string[] = [];
        let selectedGroups: number[] = [];
        responseCategories?.forEach((category) =>
          category?.sub_categories?.forEach((sub) =>
            sub?.events?.forEach((event) => {
              if (event.selected && !selecteds.includes(event.event_id)) {
                selecteds.push(event.event_id);
                event.group_id && selectedGroups.push(event.group_id);
              }
            })
          )
        );

        if (selecteds !== eaFilterList.event_ids)
          setEaFilterList((prevState) => ({
            ...prevState,
            event_ids: selecteds,
            group_ids: selectedGroups,
          }));
        setCategories(res?.categories ?? []);
        setCategoriesInitial(res?.categories ?? []);
        setOrigin(origins);
        setDestination(destinations);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getHolidaysOptions();
  }, [
    eaFilterList.origin_city,
    eaFilterList.destination,
    searchTerm,
    searchCountryCity,
  ]);

  const handleSave = () => {
    let data = {
      event_ids: eaFilterList.event_ids,
      group_ids: eaFilterList.group_ids,
    };
    HolidaysFilterSelectedSaveClient.fetchFilterSelectedSave(data)
      .then(() => {
        setEaFilterList((prevState) => ({
          ...prevState,
          updated: !prevState.updated,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickOptions = (isClear: boolean) => {
    let selected: string[] = [];
    categories.forEach((cat) =>
      cat.sub_categories.forEach((sub) =>
        sub.events.forEach((olay) => selected.push(olay.event_id))
      )
    );
    setEaFilterList((prevState) => ({
      ...prevState,
      event_ids: isClear ? [] : selected,
      group_ids: isClear ? [] : [],
    }));
  };

  //fonksiyonlardan önce kurulum
  let typingTimer; //zamanlayıcı tanımlayıcısı

  //keyup'ta geri sayımı başlat
  const onKeyUp = (event) => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => handleSearchFilter(event), 1000);
  };

  //tuşa basıldığında, geri sayımı temizle
  const onKeyDown = () => {
    clearTimeout(typingTimer);
  };

  const handleSearchFilter = (event) => {
    setSearchTerm(event?.target?.value);
  };
  return (
    <div className={styles.contanier}>
      <div className={styles.dropdown_wrapper}>
        <span>Origin</span>
        <DropdownCityAirport
          data={origin}
          added={eaFilterList.origin_city}
          setAdded={(item: any) => {
            setEaFilterList((prevState) => ({
              ...prevState,
              origin_city: item,
            }));
          }}
          singleSelected
          setSearchFilter={(str) => {
            setSearchCountryCity(str);
          }}
          isCountryAndCity
        />
      </div>
      <div className={styles.dropdown_wrapper}>
        <span>Destination</span>
        <DropdownCityAirport
          data={destination}
          added={eaFilterList.destination}
          setAdded={(item: any) => {
            setEaFilterList((prevState) => ({
              ...prevState,
              destination: item,
            }));
          }}
          disabled={eaFilterList.origin_city.length < 1}
          singleSelected
          setSearchFilter={(str) => {
            setSearchCountryCity(str);
          }}
          isCountryAndCity
        />
      </div>
      <div className={styles.button_wrapper}>
        <SideDrawer
          MenuButton={
            <span className={styles.setting_button}>
              <CiStar size={24} />
            </span>
          }
          direction="right"
          width={450}
          shutdownTrigger={() => {
            handleSave();
          }}
        >
          <div className={styles.menu_wrapper}>
            <h3 className={styles.menu_title}>Event Categories</h3>
            <button onClick={() => handleClickOptions(true)}>Clear All</button>
            {/* <button onClick={() => handleClickOptions(false)}>
              Select All
            </button> */}
            <button onClick={handleSave}>Apply Filter</button>
            <input
              type="text"
              placeholder="Search..."
              onKeyUp={onKeyUp}
              onKeyDown={onKeyDown}
            />
            {categories.map((category, index) => (
              <MenuItem
                key={index}
                category={category}
                expandSubCategory={expandSubCategory}
                setExpandSubCategory={setExpandSubCategory}
              />
            ))}
          </div>
        </SideDrawer>
      </div>
    </div>
  );
};

export default HolidaysFilter;
