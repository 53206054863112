import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useContext,
} from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import styles from "./styles.module.css";
import { EAFilterContext } from "context-api/FilterContextEA";

export interface IEvent {
  event_name: string;
  event_id: string;
  selected: boolean;
  group_id: null | number;
}

export interface ISubCategory {
  label: string;
  value: string;
  events: IEvent[];
}

export interface ICategory {
  label: string;
  value: string;
  sub_categories: ISubCategory[];
}
interface ICategories {
  label: string;
  value: string;
  sub_categories: {
    label: string;
    value: string;
    events: {
      event_name: string;
      event_id: string;
      selected: boolean;
      group_id: null | number;
    }[];
  }[];
}

interface IMenu {
  category: ICategories;
  expandSubCategory: {
    [key: string]: boolean;
  };
  setExpandSubCategory: Dispatch<
    SetStateAction<{
      [key: string]: boolean;
    }>
  >;
}

const MenuItem = ({
  category,
  expandSubCategory,
  setExpandSubCategory,
}: IMenu) => {
  const [selectedSubCategories, setSelectedSubCategories] = useState<string[]>(
    []
  );
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [expand, setExpand] = useState(true);
  const { eaFilterList, setEaFilterList } = useContext(EAFilterContext);

  const toggleSubCategoryExpand = (subCategoryValue: string) => {
    setExpandSubCategory((prevState) => ({
      ...prevState,
      [subCategoryValue]: !prevState[subCategoryValue],
    }));
  };

  const handleCategoryCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    category: ICategories
  ) => {
    const subCategoryValues = category.sub_categories.flatMap((subCategory) =>
      subCategory.events.map((event) => event.event_id)
    );
    const subCategoryGroups = category.sub_categories.flatMap((subCategory) =>
      subCategory.events.map((event) => event.group_id)
    );

    if (event.target.checked) {
      const allEventValues = category.sub_categories.flatMap((subCategory) =>
        subCategory.events.map((event) => event.event_id)
      );
      const filtered = allEventValues.filter(
        (value) => !eaFilterList.event_ids.includes(value)
      );
      const allEventGroups = category.sub_categories.flatMap((subCategory) =>
        subCategory.events.map((event) => event.group_id)
      );
      const filteredGroups = allEventGroups
        .map((value) => (value ? value : -1))
        .filter((val) => val !== -1 && !eaFilterList.group_ids.includes(val));
      setEaFilterList((prevState) => ({
        ...prevState,
        event_ids: [...prevState.event_ids, ...filtered],
        group_ids: [...prevState.group_ids, ...filteredGroups],
      }));

      setSelectedSubCategories([
        ...selectedSubCategories,
        ...category.sub_categories.map((subCategory) => subCategory.value),
      ]);
      setSelectedCategories([...selectedCategories, category.value]);
    } else {
      setEaFilterList((prevState) => ({
        ...prevState,
        event_ids: prevState?.event_ids.filter(
          (value) => !subCategoryValues.includes(value)
        ),
        group_ids: prevState?.group_ids.filter(
          (value) => !subCategoryGroups.includes(value)
        ),
      }));

      setSelectedSubCategories(
        selectedSubCategories.filter(
          (value) =>
            !category.sub_categories
              .map((subCategory) => subCategory.value)
              .includes(value)
        )
      );
      setSelectedCategories(
        selectedCategories.filter((value) => value !== category.value)
      );
    }
  };

  const handleSubCategoryCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    subCategory: ISubCategory
  ) => {
    const eventValues = subCategory.events.map((event) => event.event_id);
    const eventGroups = subCategory.events.map((event) => event.group_id);
    if (event.target.checked) {
      const filtered = eventValues.filter(
        (value) => !eaFilterList.event_ids.includes(value)
      );
      const filteredGroup = eventGroups
        .map((el) => (el ? el : -1))
        .filter(
          (value) => value !== -1 && !eaFilterList.group_ids.includes(value)
        );
      setEaFilterList((prevState) => ({
        ...prevState,
        event_ids: [...prevState?.event_ids, ...filtered],
        group_ids: [...prevState?.group_ids, ...filteredGroup],
      }));

      setSelectedSubCategories([...selectedSubCategories, subCategory.value]);
    } else {
      setEaFilterList((prevState) => ({
        ...prevState,
        event_ids: prevState?.event_ids.filter(
          (value) => !eventValues.includes(value)
        ),
        group_ids: prevState?.group_ids.filter(
          (value) => !eventGroups.includes(value)
        ),
      }));

      setSelectedSubCategories(
        selectedSubCategories.filter((value) => value !== subCategory.value)
      );
    }
  };

  // Her bir olayın seçilme durumunu belirleyen fonksiyon.
  const handleEventCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    eventValue: string,
    groupId: number | null
  ) => {
    if (event.target.checked) {
      if (groupId) {
        setEaFilterList((prevState) => ({
          ...prevState,
          event_ids: [...prevState?.event_ids, eventValue],
          group_ids: [...prevState?.group_ids, groupId],
        }));
      } else
        setEaFilterList((prevState) => ({
          ...prevState,
          event_ids: [...prevState?.event_ids, eventValue],
        }));
    } else {
      setEaFilterList((prevState) => ({
        ...prevState,
        event_ids: prevState?.event_ids.filter((value) => value !== eventValue),
        group_ids: prevState?.group_ids.filter((value) => value !== groupId),
      }));
    }
  };

  const isCategoryChecked = (category: ICategory): boolean => {
    return category.sub_categories.every((subCategory) =>
      subCategory.events.every((event) =>
        eaFilterList.event_ids.includes(event.event_id)
      )
    );
  };

  const isSubCategoryChecked = (subCategory: ISubCategory): boolean => {
    return subCategory.events.every((event) =>
      eaFilterList.event_ids.includes(event.event_id)
    );
  };

  useEffect(() => {
    setSelectedCategories(
      category.sub_categories.every((subCategory) =>
        subCategory.events.every((event) =>
          eaFilterList.event_ids.includes(event.event_id)
        )
      )
        ? [category.value]
        : []
    );
  }, [
    category.value,
    category.sub_categories,
    selectedSubCategories,
    eaFilterList.event_ids,
  ]);
  const categorySelectedCount = (category: ICategories) => {
    let allId: string[] = [];
    category.sub_categories.forEach((sub) => {
      sub.events.forEach((event) => {
        allId.push(event.event_id);
      });
    });
    return allId.filter((el) => eaFilterList.event_ids.includes(el)).length;
  };
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.category}>
          <span
            className={styles.selected_count_category}
            data-active={categorySelectedCount(category) === 0}
          >
            {categorySelectedCount(category)}
          </span>
          <input
            type="checkbox"
            checked={isCategoryChecked(category)}
            onChange={(e) => handleCategoryCheckboxChange(e, category)}
          />
          <div className={styles.title}>
            <span>{category.label}</span>

            {expand ? (
              <IoIosArrowUp
                size={24}
                onClick={() => {
                  setExpand(!expand);
                }}
              />
            ) : (
              <IoIosArrowDown
                size={24}
                onClick={() => {
                  setExpand(!expand);
                }}
              />
            )}
          </div>
        </div>
        {expand &&
          category?.sub_categories?.map((subCategory, index) => (
            <div key={subCategory?.value}>
              <div className={styles.sub_categories}>
                <span
                  className={styles.selected_count}
                  data-active={
                    subCategory.events.filter((el) =>
                      eaFilterList.event_ids.includes(el.event_id)
                    ).length === 0
                  }
                >
                  {
                    subCategory.events.filter((el) =>
                      eaFilterList.event_ids.includes(el.event_id)
                    ).length
                  }
                </span>
                <input
                  type="checkbox"
                  checked={isSubCategoryChecked(subCategory)}
                  onChange={(e) =>
                    handleSubCategoryCheckboxChange(e, subCategory)
                  }
                />
                <div className={styles.sub_title}>
                  <span>{subCategory?.label}</span>
                  {expandSubCategory[subCategory?.value] ? (
                    <IoIosArrowUp
                      size={24}
                      onClick={() =>
                        toggleSubCategoryExpand(subCategory?.value)
                      }
                    />
                  ) : (
                    <IoIosArrowDown
                      size={24}
                      onClick={() => toggleSubCategoryExpand(subCategory.value)}
                    />
                  )}
                </div>
              </div>
              {category?.sub_categories?.length - 1 !== index &&
                !expandSubCategory[subCategory?.value] && (
                  <hr className={styles.hr} />
                )}
              {expandSubCategory[subCategory?.value] &&
                subCategory?.events?.map((event) => (
                  <div key={event.event_id} className={styles.event}>
                    <input
                      type="checkbox"
                      value={event.event_id}
                      checked={eaFilterList.event_ids.includes(event.event_id)}
                      onChange={(e) =>
                        handleEventCheckboxChange(
                          e,
                          event.event_id,
                          event.group_id
                        )
                      }
                    />
                    <span>{event.event_name}</span>
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default MenuItem;
