import Layout from "components/common/Layout/Layout";
import Holidays from "components/holidays/Calendar";
import HolidaysFilter from "components/holidays/HolidayFilter";
import MiniKpi from "components/holidays/MiniKpi";
import { useState } from "react";

const HOLIDAYS = () => {
  const [filterIsOpen, setFilterIsOpen] = useState(true);

  return (
    <Layout
      filterIsOpen={filterIsOpen}
      setFilterIsOpen={setFilterIsOpen}
      title={"Holidays"}
      filterHidden
    >
      <HolidaysFilter />
      <MiniKpi />
      <Holidays />
    </Layout>
  );
};

export default HOLIDAYS;
