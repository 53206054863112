import Layout from "components/common/Layout/Layout";
import { useState } from "react";
import ComparisonViewComponent from "components/holidays/ComparisonView";
import HolidaysFilter from "components/holidays/HolidayFilter";

const COMPARISONVIEW = () => {
  const [filterIsOpen, setFilterIsOpen] = useState(true);

  return (
    <Layout
      filterIsOpen={filterIsOpen}
      setFilterIsOpen={setFilterIsOpen}
      title={"Comparison View"}
      filterHidden
    >
      <HolidaysFilter />
      <ComparisonViewComponent />
    </Layout>
  );
};

export default COMPARISONVIEW;
